import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiSpacer,
  EuiTitle,
  EuiText,
  EuiButton
} from '@elastic/eui'

import ImageWall from '../../../../../components/Software/Android/InstarVision/ImageWalls/instarvision-android-ddns'
import FloatingGallery from '../../../../../components/Software/Android/InstarVision/Galleries/instarvision-android-ddns-floating-gallery'
import DDNSTable from '../../../../../components/Software/Android/InstarVision/Tables/instarvision-android-ddns-table'

import NavButtons from '../../../../../components/Software/Android/InstarVision/NavButtons'

import SEOHelmet from '../../../../../components/Layout/SEOHelmet'
import BreadCrumbs from '../../../../../components/Layout/BreadCrumbs'


const seodata = {
  title: 'Android App InstarVision',
  description: 'The Android app InstarVision for your Android tablet or smart phone. Control your camera when you don´t have access to your computer.',
  image: '/images/Search/P_SearchThumb_InstarVision_Android.png',
  twitter: '/images/Search/P_SearchThumb_InstarVision_Android.webp',
  locationEN: '/en/Software/Android/InstarVision/DDNS/',
  locationDE:'/de/Software/Android/InstarVision/DDNS/',
  locationFR:'/fr/Software/Android/InstarVision/DDNS/'
}

function InstarVisionDDNS(props) {
  return (
    <React.Fragment>

      <SEOHelmet
        title={seodata.title}
        description={seodata.description}
        image={seodata.image}
        twitter={seodata.twitter}
        location={props.location} />

      <BreadCrumbs
    locationBC={props.location}
          locationEN={seodata.locationEN}
          locationDE={seodata.locationDE}
          locationFR={seodata.locationFR}
          crumbLabel="DDNS" />
          
      <NavButtons />
    
      <EuiTitle size="l">
        <h1>InstarVision Surveillance Center</h1>
      </EuiTitle>
      <EuiTitle size="m">
        <h2>Android Software</h2>
      </EuiTitle>
      <EuiTitle size="s">
        <h4>Add a Camera: <em>DDNS Access</em></h4>
      </EuiTitle>
      <EuiSpacer size="l" />
      <EuiText size="s">
        <p><em>Are you missing Audio with your 1080p Model?</em>
          <EuiButton href="https://wiki.instar.com/en/Software/Mobile_Access_Over_HTTPS/" fill style={{float: 'right', color: 'white!important'}}>
            Audio & HTTPS
          </EuiButton>
        </p>
      </EuiText>
      <EuiSpacer size="xl" />
      <EuiText>
        <p>After <OutboundLink href="https://play.google.com/store/apps/details?id=de.instar.vision">installing the newest version</OutboundLink> please start our app use the <strong>+</strong> symbol in the upper right corner to add a <strong>New IP Camera</strong>.</p>
      </EuiText>
      <EuiSpacer size="l" />
      <FloatingGallery />
      <EuiSpacer size="l" />
      <EuiTitle size="m">
        <h3>Add an INSTAR DDNS IP Camera</h3>
      </EuiTitle>
      <EuiSpacer size="l" />
      <DDNSTable />
      <EuiSpacer size="l" />
      <ImageWall />
    </React.Fragment>
  );
}

export default InstarVisionDDNS