import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
} from '@elastic/eui'

export default function ImageWall() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="s" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign='left'
              paddingSize='l'
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/DDNS/ImageWall/InstarVision_Android_DDNS_01.webp'
                  alt='Add your camera using its DDNS address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='Camera View'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        Enter the Multiview Menu and click the + button in the top right corner to add your camera.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign='left'
              paddingSize='m'
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/DDNS/ImageWall/InstarVision_Android_DDNS_02.webp'
                  alt='Add your camera using its DDNS address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='Add a Camera'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        Choose &quot;Add Camera&quot; to add a camera that is already connected to your network. Or start the installation wizard to guide you through the initial setup.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/DDNS/ImageWall/InstarVision_Android_DDNS_03.webp'
                    alt='Add your camera using its DDNS address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Add new IP Camera'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          Choose to add your camera via it&apos;s local IP and / or it&apos;s DDNS address. Note that accessing your camera from the internet over the DDNS address will require a forwarded port or VPN connection,
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/DDNS/ImageWall/InstarVision_Android_DDNS_04.webp'
                    alt='Add your camera using its DDNS address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='QR Code Scanner'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          It is recommended to use the QRCode Reader to scan the code inside your camera&apos;s webUI. Alternatively, manually select your camera model by choosing your camera manufacturer and model. Note that you can also add thirdparty camera models this way.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/DDNS/ImageWall/InstarVision_Android_DDNS_05.webp'
                    alt='Add your camera using its DDNS address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Select Camera Model'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          For INSTAR cameras you have the choice between the &quot;normal&quot; and RTSP stream. Choosing RTSP sometimes gives you a better performance. But note that the RTSP stream uses it&apos;s own port - default RTSP Port 554 - that has to be forwarded as well to access your camera over the internet.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/DDNS/ImageWall/InstarVision_Android_DDNS_06.webp'
                    alt='Add your camera using its DDNS address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Search for Cameras'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          We recommend that you use the Search Function to automatically add your camera. Alternatively, type in your camera&apos;s DDNS or local IP address and click next to proceed.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>

        <EuiSpacer />

        <EuiFlexItem>
          <EuiFlexGroup gutterSize="l" direction="row" >
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/DDNS/ImageWall/InstarVision_Android_DDNS_07.webp'
                    alt='Add your camera using its DDNS address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Camera Address'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                            Type in your cameras LAN IP address or DDNS address and click OK to proceed.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/DDNS/ImageWall/InstarVision_Android_DDNS_08.webp'
                    alt='Add your camera using its DDNS address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Camera Login'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          Type in your cameras admin user login and choose if you want the connection to be encrypted.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/DDNS/ImageWall/InstarVision_Android_DDNS_09.webp'
                    alt='Add your camera using its DDNS address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Camera Web Port'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          In case of an encrypted connection add the HTTPS port (default: 443). Otherwise set the HTTP port (default: 80). Both ports can be changed in your cameras web user interface.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/DDNS/ImageWall/InstarVision_Android_DDNS_10.webp'
                  alt='Add your camera using its DDNS address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='Camera Name'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        Make sure to give your camera a unique name and proceed.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/DDNS/ImageWall/InstarVision_Android_DDNS_11.webp'
                  alt='Add your camera using its DDNS address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='Overview'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        The overview page allows you to make final changes and add additional information - e.g. your cameras local IP that can be used when you are in the same network as your camera.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/DDNS/ImageWall/InstarVision_Android_DDNS_12.webp'
                  alt='Add your camera using its DDNS address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='Verification'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        Verify that all details are correct and click test and choose the connection over the local network to let the app test the connection to your camera. Note that not all routers allow you to connect via the DDNS address from inside your local network. You might have to disconnect your phone from the local wifi to test the remote access.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}